import http from "@/api/http.js";

function search(startTime, endTime, umsOrganizationIdList) {
  return http({
    url: "/HmsCheckinStaff/Search",
    method: "post",
    data: {
      startTime: startTime,
      endTime: endTime,
      umsOrganizationIdList: umsOrganizationIdList,
      sysAppMenuName: "HmsCheckinStaff"
    }
  });
}

function deleteById(id) {
  return http({
    url: "/HmsCheckinStaff/DeleteById/" + id,
    method: "post"
  });
}

export default {
  search,
  deleteById
};
