var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hms-checkin-staff"},[_c('div',{staticClass:"header"},[_c('a-breadcrumb',{staticStyle:{"margin-bottom":"20px"}},[_c('a-breadcrumb-item',[_vm._v("健康系统")]),_c('a-breadcrumb-item',[_vm._v("员工体温记录")])],1),_c('a-row',[_c('a-col',{attrs:{"span":18}},[_c('a-select',{staticStyle:{"width":"150px","margin-right":"10px"},attrs:{"notFoundContent":"无数据","placeholder":"请选择组织架构","options":_vm.umsOrganizationList.map(function (item) { return ({
                value: item.id,
                label: item.name,
              }); }),"value":_vm.umsOrganizationValue},on:{"change":_vm.handleUmsOrganizationSelectOptionChange}}),_c('a-range-picker',{on:{"change":function (date, dateString) {
                this$1.queryDate = dateString;
              }}}),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","icon":"search"},on:{"click":_vm.handleQueryDateBtnClick}},[_vm._v("查询 ")]),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"default","icon":"export","disabled":_vm.dataSourceList.length === 0},on:{"click":_vm.handleDataSourceExport}},[_vm._v("数据导出 ")])],1),_c('a-col',{attrs:{"span":6}},[_c('a-input-search',{attrs:{"placeholder":"请输入任何想搜索的内容","allowClear":""},on:{"search":_vm.handleDataSourceSearch},model:{value:(_vm.dataSourceSearchInput),callback:function ($$v) {_vm.dataSourceSearchInput=$$v},expression:"dataSourceSearchInput"}})],1)],1)],1),_c('div',{staticClass:"body"},[_c('a-table',{attrs:{"rowKey":"id","data-source":_vm.getCurrentDataSourceList,"pagination":_vm.paginationOptions,"scroll":{ x: 1200 },"bordered":"","size":"small"},on:{"change":function (pagination) {
            _vm.paginationOptions.current = pagination.current;
            _vm.paginationOptions.pageSize = pagination.pageSize;
          }}},[_c('a-table-column',{key:"index",attrs:{"title":"序号","width":60,"data-index":"index","fixed":"left"},scopedSlots:_vm._u([{key:"default",fn:function(text, record, index){return [_c('span',[_vm._v(_vm._s((_vm.paginationOptions.current - 1) * _vm.paginationOptions.pageSize + parseInt(index) + 1))])]}}])}),_c('a-table-column',{key:"name",attrs:{"title":"姓名","width":100,"data-index":"name","fixed":"left"}}),_c('a-table-column',{key:"phone",attrs:{"title":"手机号","width":140,"data-index":"phone"}}),_c('a-table-column',{key:"gender",attrs:{"title":"性别","width":80,"data-index":"gender"}}),_c('a-table-column',{key:"temperature",attrs:{"title":"体温数值","width":80,"data-index":"temperature"}}),_c('a-table-column',{key:"status",attrs:{"title":"状态","width":100,"data-index":"status"}}),_c('a-table-column',{key:"staffNumber",attrs:{"title":"员工编号","width":180,"data-index":"staffNumber"}}),_c('a-table-column',{key:"organizationFullName",attrs:{"title":"组织名称","width":300,"data-index":"organizationFullName"}}),_c('a-table-column',{key:"idcard",attrs:{"title":"身份证号","width":200,"data-index":"idcard"}}),_c('a-table-column',{key:"createTime",attrs:{"title":"登记时间","width":200,"data-index":"createTime"}}),_c('a-table-column',{key:"operation",attrs:{"title":"操作","width":100,"data-index":"operation","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(text, record){return [_c('a-popconfirm',{attrs:{"title":"确定要删除此记录?"},on:{"confirm":function($event){return _vm.handleTableDeleteBtnClick(record.id)}}},[_c('a',[_vm._v("删除")])])]}}])})],1)],1),_c('div',{staticClass:"footer"})])}
var staticRenderFns = []

export { render, staticRenderFns }